import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import PostManagementContent from "../../domain/postManagementContent"

const index = () => {
  return (
    <div>
      <Topbar />
      <CustomLayout selectedPage={<PostManagementContent />} />
    </div>
  )
}

export default index
