import React, { useEffect, useState } from "react"
import { Button, Input, Space, Popconfirm } from "antd"
import { UpdateCollections } from "../../../../services/product"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import { onKeyPressNumber } from "../../../../customUtil/global"
import textAndRules from "../../../../utils/textAndRules"
const UpdateFeeBig = ({
  record,
  setListColections,
  setListDataSourceOfTable,
  listDataSourceOfTable,
  listColections,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)

  useEffect(() => {
    setNumberBefore(record.metadata?.feeRetail)
    setNumberAfter(record.metadata?.feeRetail)
  }, [record])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Space size={5} direction="horizontal">
        <Input
          value={numberAfter}
          type="number"
          style={{ width: 46, height: 25 }}
          onKeyPress={onKeyPressNumber}
          onChange={(e) => {
            console.log(e)
            if (e.target.value) {
              setNumberAfter(Number(e.target.value))
              return
            }
            setNumberAfter(0)
          }}
        />
        <div>{"%"}</div>
      </Space>
      <Space
        size={5}
        style={{
          padding: "0px 0px 0px 1rem",
          borderLeft: "1px solid #CCCCCC",
        }}
      >
        <Popconfirm
          placement="bottom"
          title={
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              {`設定ボタンを押下した時、すべて製品の ${record.title} の手数料は ${numberAfter}%になります。本当に実行しますか？ `}
            </div>
          }
          disabled={numberAfter == numberBefore}
          onConfirm={async () => {
            setIsLoading(true)
            const arrayCollectionMedium = []
            record.items.map((el) => {
              if (
                !el.metadata?.feeRetail ||
                el.metadata?.feeRetail == record.metadata?.feeRetail
              ) {
                arrayCollectionMedium.push({
                  ...el,
                  metadata: { ...el.metadata, feeRetail: numberAfter },
                })
              } else {
                arrayCollectionMedium.push(el)
              }
            })
            const { response } = await UpdateCollections({
              collections: [
                {
                  id: record.id,
                  metadata: { ...record.metadata, feeRetail: numberAfter },
                  children: arrayCollectionMedium,
                },
              ],
            })
            if (response.status == 200) {
              setIsLoading(false)
              setListColections(response.data[0])
              // setNumberBefore(numberAfter)
              // const indexOfRecord = listDataSourceOfTable
              //   .map(function (e) {
              //     return e.id
              //   })
              //   .indexOf(record.id)
              // const listDataSourceOfTableByRecord = listDataSourceOfTable.filter(
              //   (el) => el.id == record.id
              // )
              // listDataSourceOfTable.splice(
              //   indexOfRecord,
              //   listDataSourceOfTableByRecord.length,
              //   {
              //     id: record.id,
              //     title: record.title,
              //     metadata: { ...record.metadata, feeRetail: numberAfter },
              //     items: arrayCollectionMedium,
              //   }
              // )
              // console.log("listDataSourceOfTableaaaa", listDataSourceOfTable)

              // setListDataSourceOfTable([...listDataSourceOfTable])
              openNotificationChangeSuccess(
                textAndRules.updateSuccess,
                "",
                "#389e0d"
              )
            } else {
              openNotificationFail(
                "ただいま、サーバが混み合っています。",
                "しばらくしてから再度操作してください。",
                "#f81d22"
              )
            }
          }}
          okText="設定"
          cancelText="キャンセル"
        >
          <Button
            style={{ height: 25, fontSize: 10 }}
            danger
            disabled={numberAfter == numberBefore}
            loading={isLoading}
          >
            {"編集"}
          </Button>
        </Popconfirm>
      </Space>
    </div>
  )
}

export default UpdateFeeBig
