import React, { useEffect, useState } from "react"
import {
  Button,
  Spin,
  Table,
  InputNumber,
  Input,
  Space,
  Popconfirm,
} from "antd"
import { GetCollections, UpdateCollections } from "../../../services/product"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import ModalSelectCollectionMedium from "./ModalSelectCollectionMedium"
import UpdateFeeMedium from "./UpdateFeeMedium"
import UpdateFeeBig from "./UpdateFeeBig"
import "../style.scss"
import textAndRules from "../../../utils/textAndRules"

function FeeTax(props) {
  const [feeValue, setFeeValue] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [listColections, setListColections] = useState([])
  const [listDataSourceOfTable, setListDataSourceOfTable] = useState([])
  const getListColections = async () => {
    setIsLoading(true)
    const { response } = await GetCollections()
    if (response.status == 200) {
      setListColections(response.data.collections)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getListColections()
  }, [])

  useEffect(() => {
    if (listColections.length > 0) {
      const arrayConvert = []
      listColections.map((el) => {
        arrayConvert.push({
          title: el.title,
          id: el.id,
          metadata: el.metadata,
          items: el.children,
        })
      })
      console.log("arrayConvert1111", arrayConvert)
      listColections.map((el) => {
        const arrayReplace = []
        el.children?.map((ele) => {
          if (ele.metadata?.feeTax !== el.metadata?.feeTax) {
            arrayReplace.push({
              title: el.title,
              id: el.id,
              metadata: el.metadata,
              items: el.children,
              idMedium: ele.id,
              metadataMedium: ele.metadata,
              titleMedium: ele.title,
            })
          }
        })
        console.log("arrayReplace", arrayReplace)
        if (
          arrayReplace.length > 0 &&
          arrayReplace.length == el.children?.length
        ) {
          const indexOfEl = arrayConvert
            .map((element) => {
              return element.id
            })
            .indexOf(el.id)
          arrayConvert.splice(indexOfEl, 1, ...arrayReplace)
        }
        if (
          arrayReplace.length > 0 &&
          arrayReplace.length < el.children?.length
        ) {
          const arrayReplace2 = [
            ...arrayReplace,
            {
              title: el.title,
              id: el.id,
              metadata: el.metadata,
              items: el.children,
            },
          ]
          const indexOfEl = arrayConvert
            .map((element) => {
              return element.id
            })
            .indexOf(el.id)
          arrayConvert.splice(indexOfEl, 1, ...arrayReplace2)
        }
      })
      console.log("arrayConvert2222", arrayConvert)

      setListDataSourceOfTable(arrayConvert)
    }
  }, [listColections])
  console.log("listDataSourceOfTable", listDataSourceOfTable)

  const columns = [
    {
      title: <div style={{ fontSize: 12 }}>{"大カテゴリ"}</div>,
      dataIndex: "title",
      render: (_, record, index) => {
        const obj = {
          children: record.title,
          props: {},
        }
        if (index == 0) {
          const length = listDataSourceOfTable.filter(
            (el) => el.title == record.title
          ).length
          obj.props.rowSpan = length
          console.log("obj", obj)
        }
        if (index > 0) {
          if (
            listDataSourceOfTable[index].title ==
            listDataSourceOfTable[index - 1].title
          ) {
            obj.props.rowSpan = 0
          } else {
            const length2 = listDataSourceOfTable.filter(
              (el) => el.title == record.title
            ).length
            obj.props.rowSpan = length2
          }
        }
        return obj
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className={"fixInput"}
        >
          <div>{"手数料率"}</div>
          <Space size={5} direction="horizontal">
            <InputNumber
              style={{ height: 25 }}
              type="number"
              min={0}
              max={100}
              value={feeValue}
              onChange={(value) => {
                setFeeValue(value)
              }}
            />
            <div>{"%"}</div>
            <Popconfirm
              disabled={!feeValue}
              placement="bottom"
              title={
                <div style={{ fontSize: 14, fontWeight: 600 }}>
                  {
                    "Xác nhận sẽ thay đổi phí của tất cả sản phẩm, Bạn có chắc chắn muốn thay đổi không? "
                  }
                </div>
              }
              onConfirm={async () => {
                console.log("listColections", listColections)
                setIsLoading(true)
                const listCollectionsHanlde = listColections.map((el) => {
                  const arrayCollectionMedium = el.children.map((ele) => {
                    if (
                      !ele.metadata?.feeTax ||
                      ele.metadata?.feeTax == el.metadata?.feeTax
                    ) {
                      return {
                        ...ele,
                        metadata: { ...el.metadata, feeTax: feeValue },
                      }
                    } else {
                      return ele
                    }
                  })
                  return {
                    id: el.id,
                    metadata: { ...el.metadata, feeTax: feeValue },
                    children: arrayCollectionMedium,
                  }
                })
                console.log("listCollectionsHanlde", listCollectionsHanlde)
                const { response } = await UpdateCollections({
                  collections: listCollectionsHanlde,
                })
                if (response.status == 200) {
                  setIsLoading(false)

                  setListColections(response.data[0])
                  openNotificationChangeSuccess(
                    textAndRules.updateSuccess,
                    "",
                    "#389e0d"
                  )
                } else {
                  setIsLoading(false)

                  openNotificationFail(
                    "ただいま、サーバが混み合っています。",
                    "しばらくしてから再度操作してください。",
                    "#f81d22"
                  )
                }
              }}
              okText="確認"
              cancelText="キャンセル"
            >
              <Button
                style={{ height: 25, fontSize: 10 }}
                disabled={!feeValue}
                danger
              >
                {"一括設定"}
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
      render: (_, record, index) => {
        const obj = {
          children: (
            <UpdateFeeBig
              setListColections={setListColections}
              record={record}
              listDataSourceOfTable={listDataSourceOfTable}
              setListDataSourceOfTable={setListDataSourceOfTable}
            />
          ),
          props: {},
        }
        if (index == 0) {
          const length = listDataSourceOfTable.filter(
            (el) => el.title == record.title
          ).length
          obj.props.rowSpan = length
        }
        if (index > 0) {
          if (
            listDataSourceOfTable[index].title ==
            listDataSourceOfTable[index - 1].title
          ) {
            obj.props.rowSpan = 0
          } else {
            const length2 = listDataSourceOfTable.filter(
              (el) => el.title == record.title
            ).length
            obj.props.rowSpan = length2
          }
        }
        return obj
      },
    },
    {
      title: <div style={{ fontSize: 12 }}>{"中カテゴリ"}</div>,
      render: (_, record) => {
        if (!record.metadata.feeTax && record.metadata.feeTax !== 0) {
          return <div>{"大型タイプの料金をインストールしてください。"}</div>
        }
        if (record.titleMedium) {
          return <div>{record.titleMedium}</div>
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{"カテゴリ設定"}</div>
            <div>
              <ModalSelectCollectionMedium
                record={record}
                setListDataSourceOfTable={setListDataSourceOfTable}
                listDataSourceOfTable={listDataSourceOfTable}
                listColections={listColections}
              />
            </div>
          </div>
        )
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{"手数料率"}</div>
          {/* <Space size={5} direction="horizontal">
            <Input style={{ width: 46, height: 25 }} />
            <div>{"%"}</div>
            <Button style={{ height: 25, fontSize: 10 }} danger>
              {"一括設定"}
            </Button>
          </Space> */}
        </div>
      ),
      render: (_, record) => {
        if (record.idMedium) {
          return (
            <UpdateFeeMedium
              record={record}
              setListDataSourceOfTable={setListDataSourceOfTable}
              listDataSourceOfTable={listDataSourceOfTable}
              listColections={listColections}
            />
          )
        }
      },
    },
  ]

  const InputIcon = () => {
    return (
      <div className="flex flex-col cursor-pointer">
        <div
          className="cursor-pointer pb-1"
          onClick={() => {
            setFeeValue((pre) => pre + 1)
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 8 6"
            fill="none"
            padding="8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 0.000244141L7.4641 6.00024H0.535898L4 0.000244141Z"
              fill="#E60020"
            />
          </svg>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (feeValue < 1) {
              console.log("error")
              setFeeValue(0)
            } else {
              setFeeValue((pre) => pre - 1)
            }
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 8 6"
            fill="none"
            padding="8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6.00024L0.535899 0.000243488L7.4641 0.000244094L4 6.00024Z"
              fill="#E60020"
            />
          </svg>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        width: "100%",
        padding: "16px 20px",
        background: "#FFFFFF",
        borderRadius: "6px",
      }}
    >
      <Spin spinning={isLoading}>
        {isLoading && listColections.length == 0 && (
          <div style={{ height: 500, width: "100%" }}></div>
        )}
        {listColections.length > 0 && (
          <Table
            columns={columns}
            dataSource={listDataSourceOfTable}
            pagination={false}
            rowKey="index"
          />
        )}
      </Spin>
    </div>
  )
}

export default FeeTax
